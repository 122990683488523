
#services-section{
  margin-top: 100px;
}

.section-heading{
    letter-spacing: 0.5px;
    font-size: 22px;
    font-weight: bold;
  }


.services .card{
    border-radius: 8px;
    box-shadow: none !important;
    border: none !important;
  }
  

  
  .services .card-body{
    transform: scale(1.1);
    transition: 0.3s;
  }
  
 .card:hover{
    transform: scale(1.1);
    transition: 0.3s;
    background-color: #f8f4f4 !important;
  }

  .services .card .card-body h5{
    color:rgba(0,0,0,0.8) !important;
    letter-spacing: 1px !important;
  }

.card .card-body h4{
    font-weight: 500 !important;
    font-size: 20px !important;
    letter-spacing: 1px;
  }
  
 .card .card-body p{
    text-overflow: ellipsis !important;
    height: 14*1.5*4 !important;
    font-size: 14px !important;
    overflow: hidden !important;
    -webkit-line-clamp: 4 !important;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4 !important;
  }

  .services-icon{
    height: 100px;
    width: 50px;
  }