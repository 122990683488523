@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Lora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,600;0,700;1,700&display=swap');

.services-container {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  margin-bottom: 150px;
}

.services-header{
  background-image: url('../../../assets/business.jpeg');
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
  height: 45vh;
  margin-bottom: 5%;
}

.typing-container {
  position: relative;
  padding-top: 15vh;
  color: #f3e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-family: 'Libre Baskerville', sans-serif;
  text-align: center;
}
.typing-container .typing-cursor {
  color: #f3e8e8; 
}

.typing-container-p {
  padding-top: 2%;
  color: #f3e8e8;
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  align-items: center;
  font-size: 12px;
  font-family:  sans-serif;
  text-align: center;
}

.service {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 1rem; 
}

.service-images {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-images img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.service-text {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-header {
  font-family: 'Lora', serif;
  color: #333;
  align-self: flex-start; 
  margin: 0 0 0.5rem 0; 
}

.service-text-box {
  font-family: 'Roboto', sans-serif;
  color: #555;
  border: 1px solid #ccc;
  padding: 1rem;
  max-width: 500px;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px){
  .hide-if-mobile{
    display: none;
  }
}


@media (min-width: 768px) {

  .services-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding-left: 15%;
    align-items: center;
  }

  .hide-if-desktop{
    display: none;
  }

  .services-container {
    position: relative;
  }

  .service {
    flex-direction: row;
    justify-content: space-between;
  }

  .service-images {
    width: 40%; 
  }

  .service-text-right{
    margin-right: 15%;
  }
  .service-text{
    
    width: 40%;
    align-self: flex-start;
  }

  .service-images {
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .service-text {
    justify-content: flex-start;
    padding-left: 1rem;
    text-align: left;
  }

  .service-text-box {
    max-width: none;
    width: auto;
  }

}
