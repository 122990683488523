.expertise-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
    padding: 10px;
  }
  
  .image-section {
    display: flex;
    flex: 2.2;
  }
  
  .image-container {
    position: relative;
    display: flex;
    justify-content: space-evenly;
  }

  @media (max-width: 500px) {
    .image {
      width: 100%;
      height:30vh;
      display: block;
    }

    .caption{
      font-size: 16px;
    }
  }

  @media (min-width: 500px) and (max-width: 700px){
    .image {
      width: 100%;
      height:40vh;
      display: block;
    }

    .caption{
      font-size: 20px;
    }
  }


  @media (min-width: 701px) {
    .image {
      width: 100%;
      height: 55vh;
      display: block;
    }

    .caption{
      font-size: 24px;
    }
  }

  @media (min-width: 992px) {
    .text-section {
      flex: 1;
      align-self: center;
      margin-left: 40px;
    }
  }

  .image-gradient-container::after{ 
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%;
      height: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0.5298494397759104) 0%, rgba(0,0,0,0.15449929971988796) 50%, rgba(255,255,255,0) 100%);
  }
  
  .caption {
    position:absolute;
    bottom: 10px;
    width:100%;
    color: rgb(240, 240, 240);
    padding: 5px;
    font-weight: bold;
    width: 100%;
    text-align:center;
  }
  
  .text-section {
    flex: 1;
    align-self: center;
  }
  
  .text-section h2 {
    font-size: 40px;
    margin-bottom: 10px;
    color: #555555;
    font-family: "open sans", sans-serif;
    font-weight: bold;
  }
  
  .text-section p {
    font-size: 16px;
    line-height: 1.5;
    color: #040404;
    font-family: "open sans", sans-serif;
    word-spacing: 3px;
    letter-spacing: 1px;
  }
