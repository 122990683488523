img{
    height:100vh;
    width: 100%;
    object-fit: cover; 
    background-repeat: no-repeat; 
    background-position: center center;
}

.img-wrapper{
    overflow:hidden;
}

img.hover-zoom {
    animation: swing ease-out 15s forwards;
    width: 100%;
}

@keyframes swing {
    0% { transform: scale(1); }
    100% { transform: scale(1.25); }
  }