.section-heading{
    letter-spacing: 0.5px;
    font-size: 22px;
    font-weight: bold;
  }

  #contact-section{
    width: 80vw;
    border-radius: 2px;
  }

  .contact-us-card{
    border: none !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  }

  .font-weight-bold{
    font-weight: bold;
    margin-right: 5px;
  }


.contact-section-card .contact-section-card-body h4{
    font-weight: 500 !important;
    font-size: 20px !important;
    letter-spacing: 1px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  }
  
  .contact-section-card .contact-section-card-body p{
    width: 100% !important;
    text-overflow: ellipsis !important;
    height: 14*1.5*4 !important;
    font-size: 14px !important;
    overflow: hidden !important;
    -webkit-line-clamp: 4 !important;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4 !important;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12); 
  }

  
  /* map contact us */
  .map-container {
    height: 220px;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  }
  
  .map-container iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  

.contact-section-card{
    border-radius: 8px;
  }
  
.contact-section-card:hover{
    background-color: var(--white-light-color) !important;
  }
  
.contact-section-card:hover .contact-section-card-body{
    transform: scale(1.1);
    transition: 0.3s;
  }
  
.contact-section-card .contact-section-card-body h5{
    color:rgba(0,0,0,0.8) !important;
    letter-spacing: 1px !important;
  }

.contact-us-submit-button{
    border-radius: 6px;
    font-size: 13px;
    letter-spacing: 1.6px;
    font-weight: bold;
    background-color: #4285F4;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.contact-us-form-row{
    margin: 10px 0;
}

.contact-us-form-field-label{
  color: #757575;
}
