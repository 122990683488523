
.filters {
    background-color: #2d3748;
    padding: 16px;
    text-align: center;
}

.filters button {
    margin: 4px;
    padding: 8px 16px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.filters button:hover {
    background-color: #3182ce;
}

.filters .inactive {
    background-color: #e2e8f0;
    color: #2d3748;
}

.filters .inactive:hover {
    background-color: #cbd5e0;
}

.cards {
    display: grid;
    background-color: #2d3748;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 8px;
    padding: 10px;
    flex: 1;
}

.projectcard {
    position: relative;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 350px;
    cursor: pointer; 
}

.projectcard-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease-in-out;
}

.projectcard:hover .projectcard-img {
    transform: scale(1.2);
}

.projectcard-overlay {
    position: absolute;
    margin: 5%;
    width: 90%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    align-items: center;
    justify-content: center;
    transition: left 0.5s ease-in-out;
    opacity: 0;
}
.projectcard-overlay.visible {
    left: 0;
    opacity: 1;
}
.overlay-header{
    margin-top: 30%;
    font-size: larger;
}
.overlay-text{
    position: absolute;
    width: 100%;
    text-align: center;
}

.projectcard h3 {
    font-size: 1.25rem;
    font-weight: bold;
    z-index: 1;
    text-align: center;
    padding: 16px;
}


.pagination {
    background-color: #2d3748;
    color: white;
    display: flex;
    justify-content: center;
    padding: 16px;
}
.pagination button {
    margin: 4px;
    padding: 8px 16px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #3182ce;
}

.pagination .inactive {
    background-color: #e2e8f0;
    color: #2d3748;
}

.pagination .inactive:hover {
    background-color: #cbd5e0;
}

.pagination span {
    margin: 0 8px;
    padding: 8px 16px;
    cursor: pointer;
}

.direction{
    background-color: #00aacc !important;
    color: #ffffff !important;
}
.direction:disabled{
    background-color: #cccccc !important; 
    color: #666666 !important;
    border: 1px solid #999999;
}
.pagination span:hover {
    color: black;
    background-color: #edf2f7;
    border-radius: 4px;
}

@media (max-width: 640px) {
    .pagination span:first-child {
        margin-right: auto;
    }
    .pagination span:last-child {
        margin-left: auto;
    }
}
