/* AboutUsPage.css */
.about-us-container {
    position: relative;
    /* background-color: #f8f9fa; */
    color: #333;
    padding-bottom: 20px;
}

header {
    background-color: #343a40;
    color: #fff;
    padding: 20px;
    text-align: center;
}

p {
    text-align: center;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/background3.png');
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
}

.content-container {
    position: relative;
    z-index: 1;
    /* max-width: 800px; */
    margin: 20px auto;
    padding: 0 20px;
}

.about-section,
.team-section {
    /* background-color: #fff; */
    border-radius: 10px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    padding: 30px;
    margin-bottom: 30px;
}

#borderRight, #borderLeft {
    flex: 1;
}

.about-section h2,
.team-section h2 {
    color: #343a40;
    border-bottom: 2px solid #343a40;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
}

.highlight {
    color: #007bff;
}

.fancy-text {
    font-style: italic;
    font-weight: bold;
    color: #28a745;
}

.team-image {
    width: 100%;
    margin-top: 20px;
}

.contain-about-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.team-member {
    text-align: center;
    flex: 1;
}

.our-story {
    display: flex;
    width: 100%;
}


.our-story div{
    height: 15vw;
    width: 15vw;
    background-image:url(../../assets/1.jpg);
    background-size: 100%;
    border-radius: 10vw;
    margin-right: 5%;
}

.our-story p {
    flex: 1;
    text-align: justify;
}

.awards-section {
display: flex;
flex-wrap: wrap;
justify-content: center;
}

.award {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    align-items: center;
    padding-top: 10px;
    flex: 1;
    /* width: 15%; */
    max-width:15%;
    margin: 10px;
    min-width: 120px;
}

.award img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* margin-bottom: 10px; */
    flex: 1;
    
}

.award p {
    flex: 1;

}

.our-story img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}


.team-member img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
}

/* .team-member  p {
    text-align: center;
    align-items: center;
} */