.admin-login-page{
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 113px);
    margin-top: -111px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



@media (max-width: 500px){
    .login-form-card{
        width: 90%;
        padding: 40px;
        border: none !important;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }

  @media (min-width: 501px){
    .login-form-card{
        width: 50%;
        padding: 40px;
        border: none !important;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }

  .login-error{
    font-size: small;
    color: rgb(237, 86, 86);
  }
