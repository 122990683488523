.image-picker-layout {
}

.images-list {
}

.images-list-item {
  position: relative;
  display: inline-block;
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  border: 0.1px solid rgba(86, 86, 86, 0.478);
  margin-right: 5px;
  margin-left: 15px;
}

.images-list-item-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the image fits into the container */
  opacity: 1; /* Initial opacity */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.images-list-item-icon {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30px;
  width: 30px;
  color: white;
  transform: translate(-50%, -50%);
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.images-list-item-opacity {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.images-list-item:hover .images-list-item-icon {
  opacity: 1; /* Display delete icon on hover */
  height: 30px;
  width: 30px;
}

.images-list-item:hover .images-list-item-opacity {
  display: block;
}
