.navbar{
    /* position: absolute;
    top: 0; */
}

.logo{
    max-height: 45px;
    max-width: 50vw;
    object-fit: contain;
}

.navbar-toggle{
    border: 0 !important;
}

.navbar-toggle:focus {
    border: 0 !important;
    box-shadow: none !important;
}



@media (max-width: 991px) {
    .nav-link{
        margin-left: 24px !important;
        letter-spacing: 0.15rem;
        font-weight: bold;
    }

    .nav-item:hover{
        color: white;
        background-color: rgb(143, 144, 144, 0.2);
        transition: 0.1s;
    }
}

@media (min-width: 992px) {
    .nav-link{
        margin-left: 12px !important;
        margin-right: 12px !important;
        letter-spacing: 0.15rem;
        font-weight: bold;
    }

    .navbar .navbar-nav .nav-item {
        position: relative;
      }
    
    .navbar .navbar-nav .nav-item::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        content: "";
        background-color: black;
        width: 0%;
        height: 2px;
        transition: all 0.2s;
      }
      .navbar .navbar-nav .nav-item:hover::after {
        width: 100%;
      }
}

.nav-link:active > #offcanvasNavbar{
    color: black;
}

.navbar-brand{
    justify-content: center;
    align-items: center;
    display: flex;
}

#offcanvasNavbar{   
    color: grey;
    background-color: rgba(0,0,0,0.99) ;
    transition: 0.1s;
    width: 40vh;
    border: none;
}

.offcanvasHeader{
    margin-top: 30px;
    margin-right: 10px;
    color: white;
}
