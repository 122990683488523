footer{
    bottom: 0 !important;
    background-color: #1c2331;
  }

.footer-icons-container{
    margin-right: 10px;
}

.footer-icons{
    color: white;
}  

.footer-copyright{
    color: #FFFFFF99;
}

.footer-business-name{
    color: white;
    text-decoration: none;
    margin-left: 5px;
}