.slider img {
    width: 100px;
    height: 100px;
    animation: scroll 60s linear infinite;
  }

  .partner-header{
    text-align: center;
    margin-bottom: 50px;
    font-size: 2em;
    font-weight: bold;
    color:  rgba(105, 105, 105, 0.888);;
  }
  
  .slide-track {
    width: 100%;
    display: flex;
    gap: 3em;
    overflow: hidden;
  }
  
  .slider {
    background-color: whitesmoke;
    padding: 6em 1em;
  }
  
  @keyframes scroll {
    0% {transform: translateX(0);}
    100% {transform: translatex(-1000%)}
  }