.admin-dashboard-page{
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 113px);
    margin-top: -111px;
}

.admin-dashboard-layout{
    width: 100%;
    height: 100%;
    margin-top: 120px;
}