
.auto-increment-layout{
    margin: 100px;
}

.auto-increment-card{
}

.auto-increment-number{
    font-family: 'Fjalla One', sans-serif;
    font-size: 36px;
    color: #000;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 1px;
}


.auto-increment-text{
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #7F7F7F;
    text-align: center;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 1px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}